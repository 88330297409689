
import {Component, Mixins} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import AvukatTalepPicker from "@/components/pickers/uyap/AvukatTalepPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {AvukatTalepPicker, FormWrapper}
})
export default class HacizTalepHazirlaForm extends Mixins(SimpleInputMixin) {

}
