
import {Component, Mixins, Prop} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import UyapHacizTalepForm from "@/components/forms/UyapHacizTalepForm.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {UyapHacizTalepForm, FormDialog, DeleteDialog, FormWrapper}
})
export default class UyapHacizTalepListForm extends Mixins(SimpleInputMixin) {
  @Prop({required: false}) dosyaId!: number;
  @Prop({required: false}) kisiKurumId !: number;
  @Prop({required: false}) kisiMi !: boolean;

  uyapHacizEvrakTuru = UyapHacizEvrakTuru;
  hacizHeaders = [
    {text: 'Talep Türü', value: 'talepKisaAdi'},
    {text: 'Açıklama', value: 'aciklama'},
    {text: 'Masraf', value: 'talepMasrafi'},
    {text: 'İşlemler', value: 'actions', align: 'center', width: 100}
  ]

  get toplamMasraf() {
    let tutar = 0;
    this.localValue.forEach((talep: any) => {
      if (talep.talepKodu == 4)
        tutar += talep.talepMasrafi * talep.bankaList.length;
      else
        tutar += talep.talepMasrafi;
    });
    return tutar;
  }

  onAdd(v: any) {
    this.localValue.push(v);
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
  }

  onUpdate(item: any, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onSubmit() {
    this.$emit("onSuccess", this.localValue);
  }
}
