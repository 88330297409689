

import {Component, Vue} from "vue-property-decorator";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import TopluHacizHazirlaFormWizard from "@/components/forms/form-wizard/eicrapro/TopluHacizHazirlaFormWizard.vue";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, TopluHacizHazirlaFormWizard}
})
export default class TopluHacizHazirlaView extends Vue {
  taskTuru = TaskTuru;
}
